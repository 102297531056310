import { queryPlans } from '@wix/ambassador-pricing-plans-v3-plan/http';
import { PlanOptions, Plan, QueryV2, StartType } from '@wix/ambassador-pricing-plans-v3-plan/types';
import {
  CheckoutData,
  checkoutDataToQuery,
  commonIntegrationDataToQuery,
  IntegrationData,
  isCustomPurchaseFlow,
  TpaPageId,
} from '@wix/pricing-plans-router-utils';
import { IHttpClient, IWixAPI } from '@wix/yoshi-flow-editor';
import { EcomService } from '../components/PlanCustomization/services/ecom';
import { PRICING_PLANS_APP_DEF_ID } from '../constants';

export function navigateToPlanCustomization(wixCodeApi: IWixAPI, checkoutData: CheckoutData) {
  const params = checkoutDataToQuery(checkoutData);
  return wixCodeApi.location.to?.('/pricing-plans/plan-customization?' + params.toString());
}

export function getPlanOptions(data: {
  plan: Plan;
  submissionId?: string | null;
  startDate?: string | null;
  integrationData?: IntegrationData;
}): PlanOptions {
  const { plan, submissionId, startDate, integrationData = {} } = data;
  const pricingVariantId = plan.pricingVariants![0].id!;
  const membersOnly = isCustomPurchaseFlow(integrationData);
  const options: PlanOptions = {
    pricingVariantId,
    // @ts-expect-error
    membersOnly,
  };

  if (submissionId) {
    options.submissionId = submissionId;
  }

  if (startDate && isStartDateValid(startDate)) {
    options.startDate = new Date(startDate);
  }

  return options;
}

export function isStartDateValid(dateString: string): boolean {
  const date = new Date(dateString);
  const today = new Date();
  if (date < today) {
    return false;
  }

  if (date.getDate() === today.getDate()) {
    return false;
  }

  return true;
}
export async function getCustomSuccessPageUrl(params: {
  checkoutId: string;
  wixCodeApi: IWixAPI;
  integrationData: IntegrationData;
  planId: string;
}): Promise<string | undefined> {
  const { wixCodeApi, integrationData, checkoutId, planId } = params;
  const { relativeUrl } = await wixCodeApi.site.getSectionUrl({
    appDefinitionId: PRICING_PLANS_APP_DEF_ID,
    sectionId: TpaPageId.ThankYou,
  });
  if (!isCustomPurchaseFlow(integrationData)) {
    return;
  }

  if (!relativeUrl) {
    return;
  }

  const continuePagePath = relativeUrl?.startsWith('/') ? relativeUrl : `/${relativeUrl}`;
  const baseUrl = wixCodeApi.location.baseUrl;
  const queryParams = commonIntegrationDataToQuery(integrationData);
  queryParams.set('isEcom', 'true');
  queryParams.set('checkoutId', checkoutId);
  queryParams.set('planId', planId);
  return encodeURIComponent(`${baseUrl}${continuePagePath}?${queryParams.toString()}`);
}

interface NavigateToEcomCheckoutParams {
  wixCodeApi: IWixAPI;
  httpClient: IHttpClient;
  plan: Plan;
  integrationData?: IntegrationData;
}

export async function navigateToEcomCheckout({
  wixCodeApi,
  httpClient,
  plan,
  integrationData,
}: NavigateToEcomCheckoutParams): Promise<void> {
  const ecomService = new EcomService(httpClient, wixCodeApi);
  const options = getPlanOptions({ plan, integrationData });
  const checkout = await ecomService.createCheckout(plan.id!, options);
  const successUrl = await getCustomSuccessPageUrl({
    wixCodeApi,
    integrationData: integrationData ?? {},
    checkoutId: checkout.id!,
    planId: plan.id!,
  });
  await ecomService.navigateToCheckout(checkout.id!, successUrl);
}

export async function fetchPlanV3(filter: QueryV2['filter'], httpClient: IHttpClient): Promise<Plan> {
  const response = await httpClient.request(queryPlans({ query: { filter } }));
  return response.data.plans![0];
}

export function allowCustomStartDate(plan: Plan): boolean {
  return !!(plan.pricingVariants?.[0].billingTerms?.startType === StartType.CUSTOM);
}
